<template>
  <div class="judge-admin">
    <div class="judge-admin-header">
      <el-button type="primary" @click="addJudge">创建评委</el-button>
    </div>
    <el-table class="customTable" :data="judgeList" style="width: 100%; margin-top: 20px; flex: 1;border: 1px solid #EEEEEE;" height="1%" size="medium"
              :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
              :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
      <el-table-column prop="nickname" align="left" label="评委名称"></el-table-column>
      <el-table-column prop="username" align="center" label="账号"></el-table-column>
      <el-table-column align="center" label="头像">
        <template slot-scope="scope">
          <div class="column-logo-box">
            <div class="column-logo">
              <img v-if="scope.row.avatar" :src="scope.row.avatar" alt="">
              <img v-else src="./../../../assets/images/user_img.png" alt="">
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" align="center" label="创建时间"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-link type="success" :underline="false" @click="editData(scope.row.id)">编辑</el-link>
          <el-link type="danger" :underline="false" @click="deleteData(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="dialogTitle"
        custom-class="green-dialog"
        :visible.sync="judgeDialogVisible"
        @close="resetForm"
        @opened="openAddJudge"
        width="500px">
      <el-form ref="addForm" :model="addForm" :rules="addFormRules" label-position="top">
        <el-form-item label="评委名称" prop="nickname">
          <el-input v-model="addForm.nickname" ref="cursorInput" placeholder="2-20"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="username">
          <el-input :disabled="addForm.id ? true : false" v-model="addForm.username" placeholder="4-20"></el-input>
        </el-form-item>
        <el-form-item label="重置密码" prop="setPassword" v-if="addForm.id">
          <el-input v-model="addForm.setPassword" auto-complete="new-password" minlength="6" maxlength="20" show-password placeholder="6-20"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-else>
          <el-input v-model="addForm.password" auto-complete="new-password" minlength="6" maxlength="20" show-password placeholder="6-20"></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <div class="logo-src-box" v-if="addForm.avatar">
            <div class="src-box">
              <img :src="headerSrc" alt="">
            </div>
            <i @click="clearLogo" class="iconfont">&#xe651;</i>
          </div>
          <el-upload class="logo-uploader"
                     v-else
                     action="judge/upload"
                     :headers="headersParams"
                     name="logo"
                     :show-file-list="false"
                     :on-success="handleCoverSuccess"
                     :before-upload="beforeCoverUpload">
            <i class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
          <span class="school-prompt">建议尺寸：200*200；格式为.jpg或.jpeg或.png；大小不超过2M的图片</span>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button  @click="judgeDialogVisible = false">取 消</el-button>
        <el-button style="margin-left: 30px" type="primary" @click="confirmForm('addForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                   :current-page="listPages.currentPageNum"
                   :page-size="listPages.eachPageNum"
                   :total="listPages.total"
                   layout="prev, pager, next, jumper"
                   @current-change="listCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import { getJudgeList, judgeAdd, judgeInfo, judgeDel } from '@/utils/apis'
export default {
  name: "JudgesManagement",
  data(){
    var validateSetPass = (rule, value, callback) => {
      if(value === ''){
        callback();
      } else {
        if(value.length < 6 || value.length > 20){
          callback(new Error('长度在 6 到 20 个字'))
        } else {
          callback();
        }
      }
    }
    return {
      judgeList:[],
      listPages:{
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      dialogTitle:'',
      judgeDialogVisible:false,
      addForm:{
        id:'',
        nickname:'',
        username:'',
        password:'',
        setPassword:'',
        avatar:'',
      },
      addFormRules:{
        nickname:[
          { required: true, message: '请输入评委名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        username:[
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur' }
        ],
        password:[
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字', trigger: 'blur' }
        ],
        setPassword:[
          { validator: validateSetPass, trigger: 'blur' }
        ],
      },
      headerSrc:null,
      headersParams:{
        Authorization: localStorage.getItem('schoolToken') || ''
      },
      hostUrl:localStorage.getItem('hosturl') || '',
    }
  },
  mounted() {
    this.getJudgeList()
  },
  methods: {
    getJudgeList(){
      let params = {
        page:this.listPages.currentPageNum,
        limit:this.listPages.eachPageNum
      }
      getJudgeList(params).then((res)=>{
        this.judgeList = res.data.list;
        this.listPages.total = res.data.total;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    resetForm(){
      this.$refs.addForm.resetFields();
      this.headerSrc = null;
      this.addForm.avatar = '';
      this.addForm.id = '';
    },
    openAddJudge(){
      if(this.addForm.id){
        judgeInfo(this.addForm.id).then((res)=>{
          this.addForm.nickname = res.data.nickname;
          this.addForm.username = res.data.username;
          this.addForm.avatar = res.data.avatar;
          this.headerSrc = this.hostUrl + res.data.avatar;
        }).catch((err)=>{
          console.log('err', err)
        })
      }
      this.$refs.cursorInput.focus();
    },
    addJudge(){
      this.dialogTitle = '创建评委';
      this.judgeDialogVisible = true;
    },
    // 删除头像
    clearLogo(){
      this.headerSrc = null;
      this.addForm.avatar = '';
    },
    // start 上传图片
    beforeCoverUpload(file) {
      const isImg = file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg') || file.name.toLowerCase().endsWith('.png');
      if (!isImg) {
        this.$message.error('上传图片只能是 jpg 或 jpeg 或 png 格式!');
        return false;
      }
      return isImg;
    },
    // 上传图片
    handleCoverSuccess(res, file){
      if (res.code === 200) {
        this.headerSrc = this.hostUrl + res.data.src; // 有域名
        this.addForm.avatar = res.data.src; //无域名
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    editData(id){
      this.addForm.id = id;
      this.dialogTitle = '编辑评委';
      this.judgeDialogVisible = true;
    },
    deleteData(id){
      this.$confirm(`是否删除该评委除将无法恢复`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass:'green-message-box',
        type:'warning'
      }).then(()=>{
        judgeDel(id).then((res)=>{
          this.$message({
            message:res.msg,
            type:'success',
            duration:1000,
            onClose:()=>{
              this.getJudgeList()
            }
          })
        }).catch((err)=>{
          console.log('err', err)
        })
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    confirmForm(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          let formData = new FormData();
          formData.append('nickname', this.addForm.nickname);
          formData.append('username', this.addForm.username);
          formData.append('avatar', this.addForm.avatar);
          if(this.addForm.id){
            formData.append('id',this.addForm.id);
            if(this.addForm.setPassword){
              formData.append('password', this.addForm.setPassword)
            }
          } else {
            formData.append('password', this.addForm.password)
          }
          judgeAdd(formData).then((res)=>{
            this.$message({
              message:res.msg,
              type:'success',
              duration:1000,
              onClose:()=>{
                this.judgeDialogVisible = false;
                this.getJudgeList()
              }
            })
          }).catch((err)=>{
            console.log('err',err)
          })
        } else {
          return false;
        }
      })
    },
    listCurrentChange(val){
      this.listPages.currentPageNum = val;
      this.getJudgeList();
    }
  }
}
</script>

<style scoped lang="scss">
.judge-admin{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .judge-admin-header{
    display: flex;
    justify-content: flex-end;
  }
}
.school-prompt{
  color: #999;
  font-size: 12px;
}
.logo-src-box{
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  position: relative;
  .src-box{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #EEEEEE;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  i{
    line-height: 1;
    font-size: 14px;
    color: #DCDCDC;
    position: absolute;
    right: -20px;
    top: 0;
    cursor: pointer;
  }
}
.logo-uploader{
  ::v-deep.el-upload{
    width: 80px;
    height: 80px;
    background: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    .logo-uploader-icon{
      font-size: 32px;
      color: #A0A0A0;
    }
  }
}
.column-logo-box{
  display: flex;
  align-items: center;
  justify-content: center;
  .column-logo{
    width: 48px;
    height: 48px;
    border: 1px solid #EEEEEE;
    border-radius: 50%;
    overflow: hidden;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>